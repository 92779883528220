import React, { useEffect, useState } from 'react'
import { Box, LabelStyled, SpanStyled } from '../Styled/Elements';
import { useTranslation } from 'react-i18next';

const FindValue = () => {
    const { t } = useTranslation()
    const [inputValue, setInputValue] = useState(null);
    const [fromUnit, setFromUnit] = useState('Celsius');
    const [toUnit, setToUnit] = useState('Kelvin');
    const [outputValue, setOutputValue] = useState(null);
    const [resultValue, setResultValue] = useState(null);

    const units = [
        'Celsius',
        'Kelvin',
        'Fahrenheit',
    ];



    const convertFromCelsius = (value, toUnit) => {
        switch (toUnit) {
            case 'Celsius':
                return value;
            case 'Kelvin':
                return value + 273.15;
            case 'Fahrenheit':
                return (value * 9 / 5) + 32;
            default:
                return ' ';
        }
    };
    const convertFromKelvin = (value, toUnit) => {
        switch (toUnit) {
            case 'Celsius':
                return value - 273.15;
            case 'Kelvin':
                return value;
            case 'Fahrenheit':
                return (value - 273.15) * 9 / 5 + 32;
            default:
                return '';
        }
    };
    const convertFromFahrenheit = (value, toUnit) => {
        switch (toUnit) {
            case 'Celsius':
                return (value - 32) * 5 / 9;
            case 'Kelvin':
                return (value - 32) * 5 / 9 + 273.15;
            case 'Fahrenheit':
                return value;
            default:
                return '';
        }
    };

    const convertUnits = () => {
        const value = parseFloat(inputValue);

        if (isNaN(value)) {
            setOutputValue(null);
            return;
        }

        let result;

        switch (fromUnit) {
            case 'Celsius':
                result = convertFromCelsius(value, toUnit);
                break;
            case 'Kelvin':
                result = convertFromKelvin(value, toUnit);
                break;
            case 'Fahrenheit':
                result = convertFromFahrenheit(value, toUnit);
                break;
            // Add cases for other units as needed
            default:
                setOutputValue('Invalid unit selected');
                // alert('Invalid unit selected.');
                return;
        }

        setOutputValue(result.toString());
        setResultValue(`${value} ${t(fromUnit)} = ${result.toString()} ${t(toUnit)}`)
    };


    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleFromUnitChange = (e) => {
        setFromUnit(e.target.value);
    };

    const handleToUnitChange = (e) => {
        setToUnit(e.target.value);
    };

    useEffect(() => {
        convertUnits();
    }, [inputValue, fromUnit, toUnit]);

    return (
        <Box className='box-wrapper my-4'>
            <Box className='percentage-field'>
                <Box className='unit-converter'>
                    <LabelStyled>{t('From')}</LabelStyled>
                    <input type="text" value={inputValue} onChange={handleInputChange} />
                    <select value={fromUnit} onChange={handleFromUnitChange}>
                        {units.map((unit) => (
                            <option key={unit} value={unit}>
                                {t(unit)}
                            </option>
                        ))}
                    </select>
                </Box>
                <Box className='button-select'>
                    <button className='btn btn-outline-secondary mt-4' >
                        =
                    </button>
                </Box>
                <Box className='unit-converter'>
                    <LabelStyled>{t('To')}</LabelStyled>
                    <input type="text" value={outputValue} readOnly />
                    <select value={toUnit} onChange={handleToUnitChange}>
                        {units.map((unit) => (
                            <option key={unit} value={unit}>
                                {t(unit)}
                            </option>
                        ))}
                    </select>
                </Box>
            </Box>
            {
                resultValue !== null && (
                    <Box className='mt-4 d-flex align-items-center justify-content-center'>
                        <SpanStyled className='btn btn-danger mx-2'>{t('Result')}: </SpanStyled>
                        <SpanStyled className='btn btn-outline-success mx-2'>{resultValue}</SpanStyled>
                    </Box>
                )
            }


        </Box>

    )
}

export default FindValue